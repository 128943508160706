var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "integral-page-sign" },
    [
      _c("HeadNav"),
      _c(
        "header",
        { staticClass: "df fdc alc jcc header" },
        [
          _c("div", { staticClass: "head_rules", on: { click: _vm.toRules } }, [
            _vm._v("积分规则"),
          ]),
          _c(
            "div",
            { staticClass: "isSign" },
            [
              _c("div", { staticClass: "sign-point" }, [_vm._v("我的积分")]),
              _c("yd-countup", {
                attrs: { endnum: _vm.integral, duration: "1", decimals: "2" },
              }),
            ],
            1
          ),
          _c("router-link", { attrs: { to: "integral_list" } }, [
            _c("div", { staticClass: "balanceImg" }),
            _c("div", { staticClass: "point-detail" }, [_vm._v("积分明细")]),
          ]),
        ],
        1
      ),
      _c(
        "section",
        { staticClass: "sign-up-all" },
        [
          _c("sign-up", {
            on: { changeIntegral: _vm.changeIntegral, addPoint: _vm.addPoint },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }