<template>
  <div class="sign-up">
    <div class="sign-up-title">
      <div class="sign-up-tab">
        <p class="tab-left">{{is_signed ? `您已累计签到${sign_day}天` : '今日未签到' }}</p>
        <p class="tab-left tab-right">明日签到可领取<span class="point-color">{{tomorrow_integral}}</span>积分</p>
      </div>
      <div class="continued-days">
        <div class="days-detail" v-for="(item, index) in continuedDays" :key="index">
          <div class="detail-point" :class="[item.is_signed ? index === 0 ? 'age_is_sign' :'is_sign' : index === 0 && first_sign ? 'age_no_sign' : 'no_sign']">{{Number(item.integral) > 0 ? '+' + item.integral : ''}}</div>
          <p class="detail-time" :class="{'data-color': item.show_date === '今日'}">{{item.show_date}}</p>
          <div v-if="index === 0 && !continuedDays[0].is_signed && !first_sign" class="no_reissue">缺卡</div>
        </div>
      </div>
    </div>

    <div v-if="sign_card_num > 0" class="exchange-book">
      <p class="book-amount">当前有<span class="sign-card-num">{{sign_card_num}}</span>张补签卡</p>
      <div class="book-content">
        <div class="book-cycle">
          <div class="book-logo">
            <img src="../../../assets/reissue.png" alt="">
          </div>
          <div class="content-detail">
            <p class="detail-type card-txt">一张补签卡可补签一天</p>
          </div>
          <div class="content-number">
            <p>&times;{{sign_card_num}}</p>
          </div>
          <p class="content-btn" @click="reissue">补签</p>
        </div>
      </div>
    </div>

    <div class="exchange-book">
      <p class="book-amount exchange">兑换卡</p>
      <div class="book-content">
        <div class="book-cycle">
          <div class="book-logo">
            <img src="../../../assets/reissue.png" alt="">
          </div>
          <div class="content-detail">
            <p class="detail-type">补签卡</p>
            <p class="explain">800积分可兑换一张补签卡</p>
          </div>
          <div class="content-number">
            <p>&times;1</p>
          </div>
          <p class="content-btn" @click="getCard()">兑换</p>
        </div>
      </div>
    </div>
    
    <dialog-alert :textMyPage="text_buqian" :textContent="reissue_time" :textTitle="dialog_content" :toastControl="toast_control" @confirmBtn="confirmBtn" @closeDialog="closeDialog"></dialog-alert>
  
    <!-- 补签成功提示 -->
    <div class="reissue-success">
      <div class="toast-mask" v-if="dialogClose"></div>
      <div class="toast" v-if="dialogClose">
        <div class="toast-container">
          <p class="success-txt">恭喜补卡成功！</p>
          <p class="success-point">补签{{this.success_txt[0]}}月{{this.success_txt[1]}}日获得<span>{{this.continuedDays[0].integral}}</span>积分！</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapState } from 'vuex';
import {
  getSignPageData,
  exchangeSignCard,
  supplementalSign 
} from "@/services/my";
import  DialogAlert  from "@/components/dialog/dialog"

export default {
  data() {
    return {
      continuedDays: [],
      sign_card_num: 0,  //  补签卡数量
      reissue_time: '',
      sign_card_num: 0, //  补卡数量
      toast_control: false,
      sign_day: 0,  //  连续签到天数
      tomorrow_integral: 0,  // 明日签到积分
      sign_card_price_integral: 0,  //  补签卡需要的积分
      integral: 0,  // 积分数量
      first_sign: false,  //  是否是第一天签到
      dialog_content: '',
      text_buqian: '',
      btn_type: false,
      dialogClose: false,  // 补签成功弹窗
      last_sign_date: ''  //  最近一次签到日期
    }
  },
  computed: {
    ...mapState({
    })
  },
  methods: {

    //  补签
    reissue() {
      let is_yesteday= Number(this.continuedDays[0].date.split('-').join(''))
      if (is_yesteday - Number(this.last_sign_date) > 1) {
        console.log('bufu')
         this.$dialog.toast({
          mes: '当前不符合补签规则，补签失败',
          timeout: 1500,
        });
        return
      }
      if (this.continuedDays[0].is_signed || this.continuedDays[1].is_signed) {
        this.$dialog.toast({
          mes: '当前不符合补签规则，补签失败',
          timeout: 1500,
        });
        return
      }
      if (this.first_sign) {
        this.$dialog.toast({
            mes: '当前不符合补签规则，补签失败',
            timeout: 1500,
        });
      } else {
        this.toast_control = true
        this.dialog_content = '是否确定补签？'
        this.text_buqian = ''
        this.reissue_time = this.continuedDays[0].show_date
        this.btn_type = false
      }
    },

    //  获得补签卡
    async getCard() {
      this.toast_control = true
      this.text_buqian = '是否兑换补签卡?'
      this.dialog_content = ''
      this.reissue_time = ''
      this.btn_type = true
    },

    //  获取签到详细
    async getSignPageData() {
      try {
        const res = await getSignPageData()
        if (!res.code) {
          this.is_signed = res.data.is_signed_today
          this.continuedDays = res.data.show_days
          this.last_sign_date = res.data.last_sign_date.split('-').join('')
          this.sign_day = res.data.sign_days
          this.tomorrow_integral = res.data.tomorrow_integral
          this.sign_card_num = res.data.sign_card_num
          this.integral = res.data.integral
          this.sign_card_price_integral = res.data.sign_card_price_integral
          if (res.data.first_sign_date === this.continuedDays[1].date || !res.data.first_sign_date) {
            this.first_sign = true
          }
        }
      } catch (e) {}
    },

    closeDialog() {
      this.toast_control = false
    },

    confirmBtn() {
      this.btn_type ? this.exchangeSignCard() : this.supplementalSign()
    },

    //  兑换补签卡
     async exchangeSignCard() {
       this.toast_control = false
        try {
          const res = await exchangeSignCard()
          if (!res.code) {
            this.$emit('changeIntegral', res.data.integral)
            this.sign_card_num += 1
          }
           this.$dialog.toast({
              mes: res.msg,
              timeout: 1500,
          });
        } catch (e) {}
      },

    //  补签
    async supplementalSign() {
      try {
        const res = await supplementalSign()
        if (!res.code) {
          this.toast_control = false
          let txt = this.continuedDays[0].show_date.split('.')
          this.success_txt = txt
          this.dialogClose = true
          this.getSignPageData()
          setTimeout(()=>{this.dialogClose = false}, 1500)
          this.$emit('addPoint', res.data.integral)
          this.sign_card_num -= 1
          this.continuedDays[0].is_signed = true
          this.continuedDays[0].integral = res.data.integral
        } else {
          this.$dialog.toast({
              mes: res.msg,
              timeout: 1500,
              icon: 'error'
          });
        }
      } catch (e) {}
    }
  },
  mounted() {
    this.getSignPageData()
  },
  components: {
    DialogAlert
  }
}
</script> 

<style lang="less" scoped>
@import "../../../common/less/variable.less";
@import "../../../common/less/mixin.less";  

.sign-up {
  width:7.1rem;
  height: 3.28rem;
  background:rgba(255,255,255,1);
  margin: auto;
  position: relative;
  top: -0.68rem;
  border-radius: 0.1rem 0.1rem 0 0;

  .reissue-success {
    letter-spacing: 0.02rem;
    .toast-mask {
      position: fixed;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.5);
      z-index: 100;
      width: 100%;
      height: 100%;
    }

    .toast {
      .wh(4.68rem, 2.32rem);
      background: @color-white;
      position: fixed;
      top: 50%;
      left: 50%;
      z-index: 100;
      transform: translate(-50%, -50%);
      border-radius: 0.2rem;
      display: flex;
      justify-content: center;
      align-items: center;

      .toast-container {
        text-align: center;
        .success-txt {
          font-size: 0.36rem;
          font-weight:bold;
          color:#005B8A;
          margin-bottom: 0.20rem
        }
        .success-point {
          font-size: 0.24rem;
          font-weight:500;
          color:rgba(51,51,51,1);
        }
      }
    }
  }

  .sign-up-title {
    width:7.1rem;
    height: 3.28rem;
    background:rgba(255,255,255,1);
    border-radius: 0.1rem 0.1rem 0 0;
    .sign-up-tab {
      height: 0.68rem;
      padding-top: 0.34rem;
      display: flex;
      align-items: flex-end;
      .tab-left {
        margin-left: 0.24rem;
        color:rgba(51,51,51,1);
        font-size: 0.3rem;
        font-weight:500;
      }
      .tab-right {
        font-size: 0.24rem;
        color:rgba(156,156,156,1);

        .point-color {
          color:#005B8A
        }
      }
    }

    .continued-days {
      display: flex;
      justify-content: space-around;
      padding: 0.5rem 0 0.5rem 0;
      .days-detail {
        position: relative;
        .detail-point {
          text-align: center;
          padding-top: 0.1rem;
          width: 0.92rem;
          height: 0.92rem;
          color: #B5B5B5;
          background-size: 100% 100%;
          margin-bottom: 0.18rem;
        }

        .age_no_sign {
          background-image: url("../../../assets/ago_no_sign.png");
        }

        .no_sign {
          background-image: url("../../../assets/noSign.png");
        }

        .is_sign {
          background-image: url("../../../assets/isSign.png");
          color: #005B8A;
        }

        .age_is_sign {
          background-image: url("../../../assets/ago_is_asign.png");
        }

        .no_reissue {
          width: 0.92rem;
          height: 0.92rem;
          border-radius: 50%;
          color: #fff;
          position: absolute;
          top: 0;
          left: 0;
          line-height:  0.92rem;
          text-align: center;
          background: rgba(0, 0, 0, 0.6)
        }

        .detail-time {
          text-align: center;
        }

        .data-color {
          color: #005B8A
        }
      }
    }
  }

  //  兑换册
  .exchange-book {
    margin-top: 0.2rem;
    padding-bottom: 0.26rem;
    width:7.1rem;
    // height: 3.28rem;
    background:rgba(255,255,255,1);
    border-radius: 0.1rem 0.1rem 0 0;

    .book-amount {
      // width: 2.44rem;
      height: 0.44rem;
      font-size: 0.32rem;
      font-weight:500;
      color: rgba(156,156,156,1);
      line-height: 0.44rem;
      padding: 0.26rem 0 0.26rem 0.3rem;

      .sign-card-num {
        color: #005B8A;
      }
    }

    .exchange {
      color: #333;
    }

    .book-content {
      margin: 0 0.3rem;

      .border {
        border-bottom: 0.02px solid #EBEBEB;
      }
      
      .book-cycle {
        width: 100%;
        height: 1.2rem;
        padding: 0.3rem 0.1rem;
        display: flex;
        align-items: center;
        padding: 0.2rem 0;
        position: relative;

        .book-logo {
          width: 0.72rem;
          height: 0.6rem;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .content-detail {
          padding-left: 0.38rem;
          .detail-type {
            padding-bottom: .1rem;
            color: #333333;
            font-size: 0.28rem;
            font-weight: 400;
          }

          .card-txt {
            padding: 0
          }

          .explain {
            font-size: 0.22rem;
            color: #9C9C9C;
            font-weight:400;
          }
        }

        .content-number {
        //  margin-left: 0.6rem;
          color: #333;
          font-size: 0.32rem;
          position: absolute;;
          right: 1.8rem;
        }

        .content-btn {
          width: 1.28rem;
          height: 0.56rem;
          // background: linear-gradient(180deg,#005B8A 0%,#005B8A 100%);
          border-radius: 0.28rem;
          text-align: center;
          line-height: 0.56rem;
          font-size: 0.28rem;
          font-weight: 400;
          color: #005B8A;
          position: absolute;;
          right: 0.15rem;
          border:0.02rem solid rgba(0,91,138,1);
        }
      }
    }
  }
}
</style>