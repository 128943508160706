var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "sign-up" },
    [
      _c("div", { staticClass: "sign-up-title" }, [
        _c("div", { staticClass: "sign-up-tab" }, [
          _c("p", { staticClass: "tab-left" }, [
            _vm._v(
              _vm._s(
                _vm.is_signed ? `您已累计签到${_vm.sign_day}天` : "今日未签到"
              )
            ),
          ]),
          _c("p", { staticClass: "tab-left tab-right" }, [
            _vm._v("明日签到可领取"),
            _c("span", { staticClass: "point-color" }, [
              _vm._v(_vm._s(_vm.tomorrow_integral)),
            ]),
            _vm._v("积分"),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "continued-days" },
          _vm._l(_vm.continuedDays, function (item, index) {
            return _c("div", { key: index, staticClass: "days-detail" }, [
              _c(
                "div",
                {
                  staticClass: "detail-point",
                  class: [
                    item.is_signed
                      ? index === 0
                        ? "age_is_sign"
                        : "is_sign"
                      : index === 0 && _vm.first_sign
                      ? "age_no_sign"
                      : "no_sign",
                  ],
                },
                [
                  _vm._v(
                    _vm._s(Number(item.integral) > 0 ? "+" + item.integral : "")
                  ),
                ]
              ),
              _c(
                "p",
                {
                  staticClass: "detail-time",
                  class: { "data-color": item.show_date === "今日" },
                },
                [_vm._v(_vm._s(item.show_date))]
              ),
              index === 0 && !_vm.continuedDays[0].is_signed && !_vm.first_sign
                ? _c("div", { staticClass: "no_reissue" }, [_vm._v("缺卡")])
                : _vm._e(),
            ])
          }),
          0
        ),
      ]),
      _vm.sign_card_num > 0
        ? _c("div", { staticClass: "exchange-book" }, [
            _c("p", { staticClass: "book-amount" }, [
              _vm._v("当前有"),
              _c("span", { staticClass: "sign-card-num" }, [
                _vm._v(_vm._s(_vm.sign_card_num)),
              ]),
              _vm._v("张补签卡"),
            ]),
            _c("div", { staticClass: "book-content" }, [
              _c("div", { staticClass: "book-cycle" }, [
                _vm._m(0),
                _vm._m(1),
                _c("div", { staticClass: "content-number" }, [
                  _c("p", [_vm._v("×" + _vm._s(_vm.sign_card_num))]),
                ]),
                _c(
                  "p",
                  { staticClass: "content-btn", on: { click: _vm.reissue } },
                  [_vm._v("补签")]
                ),
              ]),
            ]),
          ])
        : _vm._e(),
      _c("div", { staticClass: "exchange-book" }, [
        _c("p", { staticClass: "book-amount exchange" }, [_vm._v("兑换卡")]),
        _c("div", { staticClass: "book-content" }, [
          _c("div", { staticClass: "book-cycle" }, [
            _vm._m(2),
            _vm._m(3),
            _vm._m(4),
            _c(
              "p",
              {
                staticClass: "content-btn",
                on: {
                  click: function ($event) {
                    return _vm.getCard()
                  },
                },
              },
              [_vm._v("兑换")]
            ),
          ]),
        ]),
      ]),
      _c("dialog-alert", {
        attrs: {
          textMyPage: _vm.text_buqian,
          textContent: _vm.reissue_time,
          textTitle: _vm.dialog_content,
          toastControl: _vm.toast_control,
        },
        on: { confirmBtn: _vm.confirmBtn, closeDialog: _vm.closeDialog },
      }),
      _c("div", { staticClass: "reissue-success" }, [
        _vm.dialogClose ? _c("div", { staticClass: "toast-mask" }) : _vm._e(),
        _vm.dialogClose
          ? _c("div", { staticClass: "toast" }, [
              _c("div", { staticClass: "toast-container" }, [
                _c("p", { staticClass: "success-txt" }, [
                  _vm._v("恭喜补卡成功！"),
                ]),
                _c("p", { staticClass: "success-point" }, [
                  _vm._v(
                    "补签" +
                      _vm._s(this.success_txt[0]) +
                      "月" +
                      _vm._s(this.success_txt[1]) +
                      "日获得"
                  ),
                  _c("span", [_vm._v(_vm._s(this.continuedDays[0].integral))]),
                  _vm._v("积分！"),
                ]),
              ]),
            ])
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "book-logo" }, [
      _c("img", {
        attrs: { src: require("../../../assets/reissue.png"), alt: "" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "content-detail" }, [
      _c("p", { staticClass: "detail-type card-txt" }, [
        _vm._v("一张补签卡可补签一天"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "book-logo" }, [
      _c("img", {
        attrs: { src: require("../../../assets/reissue.png"), alt: "" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "content-detail" }, [
      _c("p", { staticClass: "detail-type" }, [_vm._v("补签卡")]),
      _c("p", { staticClass: "explain" }, [_vm._v("800积分可兑换一张补签卡")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "content-number" }, [
      _c("p", [_vm._v("×1")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }