<template>
  <div class="integral-page-sign">
    <HeadNav/>
    <header class="df fdc alc jcc header">
      <div class="head_rules" @click="toRules">积分规则</div>
      <div class="isSign">
        <div class="sign-point">我的积分</div>
        <yd-countup
                :endnum="integral"
                duration="1"
                decimals="2"
        ></yd-countup>
        <!-- <span class="all-number">{{integral}}</span> -->
      </div>
      <!-- <div v-if="status" class="point-detail" @click="toPointDetail">积分明细</div> -->
      <router-link to="integral_list">
				<div class="balanceImg"></div>
                <div class="point-detail">积分明细</div>
			</router-link>
    </header>
    <section class="sign-up-all">
      <sign-up @changeIntegral="changeIntegral" @addPoint="addPoint"></sign-up>
    </section>
  </div>
</template>

<script>
import {getUserIntegralList} from "@/services/my";
import {mapState} from 'vuex'
import Empty from '@/components/empty/Empty'
import HeadNav from "@/components/common/HeadNav";
import SignUp from "@/views/my/integral/SignUp";
import { tripartite } from "@/utils/custom";

export default {
  name: "IntegralList",
  data() {
    return {
      integral_list: [],
      is_request: 0,
      total: 0,
      page: 1, // 开始查询的下标
      listRows: 15, // 每次查询的数量
      status: false,  //  大地保险
      status_sign: false,  //  解决跟定制css混乱问题
    };
  },
  computed: {
    ...mapState({
      integral: state => state.userInfo.integral ? state.userInfo.integral : 0,//我的积分
    }),
  },
  components: {
    Empty,
    HeadNav,
    SignUp
  },
  methods: {
    toRules() {
      this.$router.push({
        path: '/integral_rules'
      });
    },
    async getUserIntegralList() {
      let params = {
        page: this.page,
        listRows: this.listRows,
      };
      try {
        const res = await getUserIntegralList(params);
        this.is_request = 1;
        if (res.code === 0) {
          const data = res.data;
          console.log(data);
          this.integral_list = [...this.integral_list, ...data.list];
          this.total = data.total;
          if (this.integral_list.length === data.total) {
            /* 所有数据加载完毕 */
            this.$refs.integral.$emit('ydui.infinitescroll.loadedDone');
            return;
          }
          /* 单次请求数据完毕 */
          this.page++;
          this.$refs.integral.$emit('ydui.infinitescroll.finishLoad');
        }
      } catch (e) {

      }
    },

    //  积分增加
    changeIntegral(value) {
      this.$store.dispatch('changePoint', value)
    },

    addPoint(value) {
      this.$store.dispatch('addPoint', value)
    },

    //  签到定制明细按钮
    toPointDetail() {
      this.status_sign = !this.status_sign
    }
  },
  mounted() {
    if ( tripartite('VUE_APP_DADIBX') ) {
      this.status = true
    }
	  this.getUserIntegralList()
  }
}
</script>

<style lang="less">
@import "../../../common/less/variable.less";
@import "../../../common/less/mixin.less";

.integral-page-sign {

 .sign-up-all {
   background: rgba(249,249,249,1);
 }

  header {
    .wh(100%, 2.88rem);
    .sc(0.30rem, @color-white);
    position: relative;
    background-image: url('../../../assets/asign.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;

    .head_rules {
      position: absolute;
      right: 0;
      top: 0.46rem;
      width:  1.24rem;
      height: 0.46rem;
      background:rgba(255,255,255,0.13);
      border-radius: 0.28rem 0 0 0.28rem;
      font-size: 0.24rem;
      text-align: center;
      line-height:  0.46rem;
    }
  
  .isSign {
    position: absolute;
    top: 0.46rem;
    left: 0.4rem;

    span:nth-child(2) {
      font-size: 0.64rem !important;
    }

    .sign-point {
      padding-bottom: 0.2rem;
    }
  }

  .point-detail {
    width: 1.24rem;
    height: 0.46rem;
    line-height: 0.46rem;
    text-align: center;
    font-size: 100%;
    position: absolute;
    right: 0;
    top: 1.2rem;
    background:rgba(255,255,255,0.13);
    border-radius: 0.28rem 0 0 0.28rem;
    font-size: 0.24rem;
  }

    .all-number {
      line-height: 0.9rem;
      .sc(0.64rem, @color-white);
    }
  }

  section {
    background: @color-white;;
    .sc(0.26rem, #333);

    .integral-title {
      .wh(100%, 0.84rem);
      line-height: 0.84rem;
      font-family: PingFang-SC-Medium;
      font-weight: 500;
      padding: 0 0.3rem;
    }

    .integral-list {
      padding: 0 0.3rem;

      .integral-item {
        .wh(100%, 1.2rem);
        padding: 0.24rem 0 0.18rem 0;
        border-bottom: 1px solid #F0F0F0;

        .left-info {
          height: 100%;
        }

        .time {
          .sc(0.22rem, @color-light-grey);
        }

        /*增加*/

        .increase {
          .sc(0.44rem, @color-add-red);
        }

        /* 减少*/

        .decrease {
          .sc(0.44rem, var(--main-color));

        }
      }
    }
    .yd-list-loading{
      background: #f5f5f5;
    }
  }
}

</style>
